/* @flow */

import React from 'react'
// import Link from 'gatsby-link'
import styles from '../../layouts/styles.module.css'

import logo from '../logos/logo-doplex.png'

const IndexPage = () => (
  <div>

    <section className={ styles.section }>
      <div className={ styles.sectionContent }>
        <img className={ styles.logo } src={ logo } alt='Doplex' />
        <h3 className={ styles.header }>
          { 'Thanks for joining our mailing list!' }
        </h3>

      </div>
    </section>

  </div>
)


export default IndexPage
